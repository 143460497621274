import React from 'react';
import { Link } from 'react-router-dom';

import ContactIcons from '../Contact/ContactIcons';

const { PUBLIC_URL } = process.env; // set automatically from package.json:homepage

const SideBar = () => (
  <section id="sidebar">
    <section id="intro">
      <Link to="/" className="logo">
        <img src={`${PUBLIC_URL}/images/me.jpg`} alt="" />
      </Link>
      <header>
        <h2>Mehmet Erim Tuzcuoğlu</h2>
        <p><a href="mailto:erimtuzcuoglu@gmail.com">erimtuzcuoglu@gmail.com</a></p>
      </header>
    </section>

    <section className="blurb">
      <h2>About Me</h2>
      <p>Hey there! I&apos;m Erim and I&apos;m thrilled to introduce myself as a Software Engineer, specializing as a Full Stack Developer at <a href="https://www.ounass.com/">Ounass</a>.
        The world of technology is my playground, where I weave intricate codes and
        embrace challenges with enthusiasm.
        Coding, traveling, and reading are my passions.
      </p>
      <ul className="actions">
        <li>
          {!window.location.pathname.includes('/resume') ? <Link to="/resume" className="button">Learn More</Link> : <Link to="/about" className="button">About Me</Link>}
        </li>
      </ul>
    </section>

    <section id="footer">
      <ContactIcons />
      <p className="copyright">&copy; Mehmet Erim Tuzcuoglu <Link to="/">erimt.dev</Link>.</p>
    </section>
  </section>
);

export default SideBar;
